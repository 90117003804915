$blueBackground: #e8f2fc;
$textColor: #545252;
$darkBlue: #4084d8;

// 1rem
html {
  font-size: 87.5%; // 1rem = 14px, 14/16
}
@media (max-width: 75em) {
  html {
    font-size: 81.25%; // 1rem = 13px, 9/16 = 56.25%
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 75%; //1rem = 8px, 8/16 = 50%
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 100%; //1rem = 12px, 12/16 = 75%
  }
}

.link {
  text-decoration: none;
  display: flex;
  color: textColor;
  line-height: 0.4rem;
}

.listItem {
  padding-top: '3px';
  padding-bottom: '3px';
  color: $textColor;
}

.listItemIcon {
  min-width: '2rem';
}

.listItemText {
  font-size: 0.9rem !important;
  color: $textColor;
  &:hover {
    color: $darkBlue;
  }
}

.listItemSelected {
  color: darkBlue;
  background-color: $blueBackground !important;

  .listItemText {
    color: $darkBlue;
  }
}

.root {
  font-size: 0.8rem;
  &:hover {
    background-color: $blueBackground;
  }
  &:selected {
    background-color: $blueBackground;
    &:hover {
      background-color: $blueBackground;
    }

    .listItemText {
      color: $darkBlue;
    }
  }
}
