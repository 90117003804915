.image_view .MuiDialogContent-root {
    padding: 8px 4px;
}

.image_navigations {
    position: absolute;
    top: 50%;
    z-index: 1000;
    color: white;
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
    width: 99%;
}