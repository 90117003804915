$darkBlue: #4084d8;
$blueBackground: #e8f2fc;
$textColor: #545252;
$primaryGreen: #00a44b;
$textColor: #545252;
$secondaryTextColor: #949494;
$primaryBlue: #577BFA;
$primaryTextColor: #9e9e9e;
$primaryGrey2: #f8f8f8;
$primaryGrey: #c4c4c4;

// 1rem
html {
  font-size: 87.5%; // 1rem = 14px, 14/16
}
@media (max-width: 75em) {
  html {
    font-size: 81.25%; // 1rem = 13px, 9/16 = 56.25%
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 75%; //1rem = 8px, 8/16 = 50%
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 100%; //1rem = 12px, 12/16 = 75%
  }
}


.table {
  // minWidth: 530;
  width: 100%;
}
.customTable {
  .MuiTableCell-sizeSmall {
    padding: 6px 0px 6px 16px; // <-- arbitrary value
  }
}

.actionsIcon {
  cursor: pointer !important;
  border: none;
  background-color: transparent;
}

.confirmButton {
  background-color: red;
  color: white;
}

.cancelButton {
  background-color: #8388a4;
  color: white;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noDataContainer {
  height: 70vh;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataSvg {
  height: 10rem;
  width: 10rem;
  margin-bottom: 2rem;
}

.filterButton {
  background-color: black;
  color: white;
  font-size: 0.9rem;
  text-transform: none;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;

  &:hover {
    background-color: black;
  }
}

.buttonContainer {
  width: 95%;
}

.formButton {
  background-color: darkBlue;
  color: white;
  font-size: 0.9rem;
  text-transform: none;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  width: 100%;
  &:hover {
    background-color: darkBlue;
  }
}

.loadingContainer {
  height: 23rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formHeader {
  width: 100%;
  height: 3rem;
  font-size: 2rem;
  padding: 16px 0;
  // borderBottom: `2px solid #e0e0e0`
}

.formHeaderText {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.form-content {
  padding: 16px 20px;
  width: 35rem;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circularProgress {
  color: white;
  height: 7px;
  width: 10px;
}

.projectCard {
  height: 14rem;
  width: 12rem;
  margin: 2rem;
  display: inline-block;
}

.main {
  background-color: #f4f5f7;
  // padding-left: 2rem;
  // padding-right: 2rem;
  height: 100vh;
  overflow-y: scroll;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mg1Vertical {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mg2Vertical {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.maxWidth {
  width: 100%;
}

.addNewProjectContainer {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectsContainer {
  height: 65vh;
  margin: 1rem 3rem;
}

.projectCardlink {
  background-color: cyan;
  text-decoration: none;
}

.projectCardImage {
  width: 100%;
  height: 10rem;
  background-color: orangeRed;
}

.projectCardName {
  text-align: center;
  margin-top: 0.5rem;
}

.left {
  display: flex;
  justify-content: end;
}

.homeHeader {
  width: 100%;
  height: 2rem;
  padding-top: 1rem;
}

.header {
  font-size: 2rem;
  font-weight: bold;
}

.newProjectBtn {
  background-color: $primaryBlue;
  border-radius: 0.5rem;
  margin-left: auto;
  color: white;
  &:hover {
    background-color: $primaryBlue;
  }
}

.profileContainer {
  height: 60vh;
  width: 100%;
  margin: 3rem auto;
}

.profileTitle {
  font-size: 2rem !important;
  font-weight: bold !important;
  margin-bottom: 1.2rem !important;
}

.profilePhotoCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem 0;
  box-shadow: 0 3px 13px $primaryGrey;
}

.actionsIcon {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.profilePicAvatar {
  height: 2rem;
  width: 2rem;
}

.profilePicture {
  height: 100%;
  width: 100%;
}

.profileName {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.profileSecondaryText {
  font-size: 0.9rem;
  color: $primaryTextColor;
}

.profileInput {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 15px;
  color: $primaryTextColor;
}

.profileLeftSection {
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  background-color: white;
}

.profileTopSection {
  padding: 2rem 1.5rem;
}

.profileMainSection {
  padding: 1rem 1.5rem;
}

.uploadImage {
  color: $primaryBlue;
}

.inputComponent {
  margin: 1rem 0px;
  border-radius: 12px;
}

.label {
  font-size: 0.8rem;
  margin: 0.2rem;
}

.input {
  border: 1px solid grey;
  border-radius: 10px;
  width: 100%;
  padding-left: 1rem;
  color: textColor;
  font-size: 0.8rem;
}

.inputSelect {
  height: 35px;
  border-radius: 10px !important;
  border: 1px solid grey;
}

.formButton {
  background-image: linear-gradient(to right bottom, #9ac7fd, #4084d8);
  color: white;
  font-size: 0.7rem;
  padding: 4px 2rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
}

.formHeader {
  width: 100%;
  height: 3rem;
  font-size: 2rem;
  padding: 16px 0;
  border-bottom: 2px solid #e0e0e0;
}

.formHeaderText {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.formContent {
  padding: 16px 20px;
  width: 35rem;
}

.loadingContainer {
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: 100vh;
  width: 100vw;
}

.sidebar {
  background-color: white;
  overflow-y: hidden;
  border-right: 1px solid rgb(218, 217, 217);

}

.imageContainer {
  height: 5rem;
  width: 100%;
  margin: 1.5rem 0;
}

.image {
  max-height: 100%;
  max-width: 100%;
}

.topActions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.topActionsLeft {
  display: flex;
}

.aRight {
  width: 100%;
  display: flex;
  justify-content: right;
}

.cPointer {
  cursor: pointer;
}

.topActionsRight {
  display: flex;
  // width: 50%;
  gap: 2rem;
}

.newButton {
  background-color: $primaryBlue;
  color: white;
  margin-left: 1rem;
  &:hover {
    background-color: $primaryBlue;
  }
}

.genericButton {
  background-color: $primaryBlue;
  color: white;
  text-transform: none;
  margin-left: 1rem;
  margin-right: 1rem;
  &:hover {
    background-color: $primaryBlue;
  }
}

.normalButton {
  background-color: white !important;
  border: 2px solid transparent !important;
  color: $textColor !important;
  &:hover {
    background-color: white !important;
    border: 2px solid $primaryBlue !important;
  }
}

.search {
  position: relative;
  border-radius: 2rem;
  background-color: white;
  // &:hover {
  //     background-color: alpha(theme.palette.common.white; 0.25);
  // };
  // margin-right: theme.spacing(2);
  margin-left: 0;
  width: 100%;

  // [theme.breakpoints.up(sm)] {
  //     // margin-left: theme.spacing(3);
  //     width: auto;
  // };
}

.searchIcon {
  padding-left: 1rem;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputRoot {
  color: inherit;
}
.inputInput {
  padding: 10px !important;
  border-radius: 0px !important;
  padding-left: 3rem !important;
}

// .inputInput {
//   padding: theme.spacing(1; 1; 1; 0);
//   // vertical padding + font size from searchIcon
//   padding-left: `calc(1em + ${theme.spacing(4)}px)`;
//   transition: theme.transitions.create(width);
//   width: 100%

//   [theme.breakpoints.up(md)] {
//       width: 20ch;
//   };
// }

.banner {
  // overflow-x: scroll !important;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
  display: flex !important;
}

.metricsCardContainer {
  display: inline;
}

.metricsCard {
  background-color: white;
  height: 7rem;
  width: 16.7rem !important;
  border-radius: 1rem;
  padding: 1rem;
  margin-right: 2rem;
}

.metricsCardFlex {
  height: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
}

.metricsCardRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.metricsCardTitle {
  color: $secondaryTextColor;
  font-size: 0.8rem !important;
}

.metric {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  line-height: 2.5rem !important;
}

.projectViewDrawer {
  width: 40vw !important;
}

.header {
  font-size: 1.8rem !important;
  font-weight: 600 !important;
  text-align: center;
  margin-top: 2rem !important;
}

.documentCategory {
  text-align: center;
}

.subHeader {
  font-style: italic;
  text-align: center;
  letter-spacing: 4px;
}

.payrollContainer {
  background-color: white;
  height: 60vh;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.payrollItem {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
}

.payrollCell {
  background-color: cornsilk;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.folderIcon {
  color: #2c358d;
  height: 8rem !important;
  width: 8rem !important;
}

.foldersContainer {
  display: flex;
}

.folder {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navTitle {
  color: #2c358d;
  font-weight: bold !important;
  margin-top: 2rem !important;
}

.poCard {
  height: 16rem;
  width: 14rem;
  margin: 2rem;
  background-color: white;
}

.iconBox {
  width: 100%;
  height: 11rem;
}

.icon {
  width: 100%;
  height: 100%;
}

.link {
  text-decoration: none;
  color: inherit;
}

.poCardActions {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.poActions {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.poCardLabel {
  text-align: center;
  margin-top: 0.5rem;
  letter-spacing: 1.2;
  font-size: 1.2rem;
  font-weight: bold;
  color: #9e9e9e;
}

.listItemSelected {
  color: darkBlue;
  background-color: $blueBackground !important;
}

.root {
  cursor: pointer;
  background-color: white;
  text-align: center;
  border-radius: 1rem;
  margin: 1rem;
  &:selected {
    background-color: $blueBackground;
    &:hover {
      background-color: $blueBackground;
    }
  }
}

.aRight {
  width: 100%;
  display: flex;
  justify-content: right;
}

.galleryContainer {
  height: 60vh;
}

.folder_listItemSelected {
  color: $primaryGreen;
  border-bottom: 1px solid $primaryGreen;

  .listItemText {
    color: $darkBlue;
  }
}

.folder_root {
  font-size: 0.8rem;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid $primaryGreen;
  }
  &:selected {
    border-bottom: 1px solid $primaryGreen;
    &:hover {
      border-bottom: 1px solid $primaryGreen;
    }

    .listItemText {
      color: $primaryGreen;
    }
  }
}
