$textColor: #545252;
$secondaryTextColor: #949494;
$primaryBlue: #577BFA;
$primaryTextColor: #9e9e9e;
$primaryGrey2: #f8f8f8;
$primaryGrey: #c4c4c4;

.aRight {
  width: 100%;
  display: flex;
  justify-content: right;
}

.cPointer {
  cursor: pointer;
}

.header {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
}

.notifications {
  background-color: white;
  border-radius: 0.7rem;
  height: 25rem;
  width: 20vw;
  overflow-y: scroll;
}

.listItemSelected {
  border-bottom: 2px solid $primaryBlue;
  background-color: $primaryGrey2 !important;

  .listItemText {
    color: $primaryBlue;
  }
}

.root {
  font-size: 0.8rem;
  color: $textColor;
  width: auto !important;
  padding-bottom: 3px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin: 0 0.5rem;

  &:hover {
    color: $primaryBlue;
  }
  &:selected {
    &:hover {
    }

    .listItemText {
      color: $primaryBlue;
    }
  }
}
